import * as firebase from "firebase/app";
import "firebase/messaging";
import "firebase/database";

var config = {
	apiKey: "AIzaSyCOIeHLvMzYut6q_HxLTly7IfuGjKMb8Hs",
	authDomain: "sophianytt.firebaseapp.com",
	databaseURL: "https://sophianytt.firebaseio.com",
	projectId: "sophianytt",
	storageBucket: "sophianytt.appspot.com",
	messagingSenderId: "908135673254",
	appId: "1:908135673254:web:00535796ceae4f5c62f367"
};

firebase.initializeApp(config);
const messaging = firebase.messaging();
const database = firebase.database();

var today = new Date();


navigator.serviceWorker.register('/wp-content/themes/sophiahemmet/firebase-messaging-sw.js')
	.then((registration) => {
		messaging.useServiceWorker(registration);

		messaging.requestPermission()
			.then(function () {
				console.log('Have premission');
				handleTokenRefresh()
			})

			.catch(function (err) {
				console.log(err);
			})
	});



function handleTokenRefresh() {
	return messaging.getToken()
		.then(function (token) {
			database.ref('/tokens').push({
				token: token,
				timestamp: today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()
			});
		})
}

messaging.onMessage(function (payload) {
	console.log('onMessage:', payload);
})