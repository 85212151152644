//Add cookie later

let newsletterbox = document.querySelector('.newsletter--box');
let newsletterpanel = document.querySelector('.newsletter--panel');
let closenewsletter = document.querySelector('.js-close-newsletter-popup');

if(document.body.contains(newsletterbox)){
	setTimeout(() => {
		newsletterbox.classList.add('newsletter--animated');
	}, 200);
}


if(document.body.contains(newsletterpanel)){
	setTimeout(() => {
		newsletterpanel.classList.add('newsletter--animated');
	}, 200);
}

if(document.body.contains(closenewsletter)){
	closenewsletter.addEventListener('click', function(e){

		if(document.body.contains(document.querySelector('.newsletter--panel'))){
			document.querySelector('.newsletter--panel').classList.remove('newsletter--animated');
		}

		if(document.body.contains(document.querySelector('.newsletter--box'))){
			document.querySelector('.newsletter--box').classList.remove('newsletter--animated');
		}

	});
}