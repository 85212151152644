let tabs = Array.from(document.querySelectorAll('#js-article-list-tab'));
let popular = document.querySelector('.article-list__popular');
let latest = document.querySelector('.article-list__latest');
let top = document.querySelector('.article-list__top');

const handleClick = (e) => {
	e.preventDefault();

	tabs.forEach(node => {
	  node.classList.remove('article-list__tab--active');
	});
	e.currentTarget.classList.add('article-list__tab--active');
	top.classList.remove('popular');
	top.classList.remove('latest');

	if(e.currentTarget.dataset.view == 'popular')
	{
		latest.classList.remove('active');
		popular.classList.add('active');
		top.classList.add('popular');
	}
	else if(e.currentTarget.dataset.view == 'latest')
	{
		popular.classList.remove('active');
		latest.classList.add('active');
		top.classList.add('latest');
	}
}

tabs.forEach(node => {
	node.addEventListener('click', handleClick)
});