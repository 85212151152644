//hamburger menu handling
let hamburgerbutton = document.querySelectorAll('#js-open-sitemenu');
let hamburgermenu = document.querySelector('#sitemenu');
let hamburgerclose = document.querySelector('#js-close-sitemenu');

//open menu
for(var i = 0; i < hamburgerbutton.length; i++){
	hamburgerbutton[i].addEventListener('click', function(e){
		let holder = this.parentNode;
		let menu = holder.getElementsByClassName("sitemenu")[0];
		menu.classList.add('sitemenu--visible');
	});
}

//close menu
hamburgerclose.addEventListener('click', function(e){
	let parent = this.parentNode.parentNode;
	parent.classList.remove('sitemenu--visible');
});