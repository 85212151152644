window.$ = require('jquery');
window.jQuery = window.$;


$(function() {

	$('.js-select-month, .js-select-category').on('change', function(){
		var year = $('.js-select-month').find(':selected').data('year');
		var month = $('.js-select-month').find(':selected').data('month');
		var term_id = $('.js-select-category').val();
		getArchivePosts(year, month, term_id);
		$('.card-section-wrap').css('opacity', '0.5');
	});

	$('.js-search-archive').on('input', function(e){
		var query = $(this).val();
		$('.card-section-wrap').css('opacity', '0.5');
		searchAjax(query);
	});

	function getArchivePosts(year, month, term_id){
		$.ajax({
			type: "POST",
			url: ajaxUrl,
			dataType:'html',
			data: {
				action: 'get_archive_posts', year: year, month: month, term_id : term_id
			},
			success: function (data) {
				setTimeout(() => {
					$('.card-section-wrap').html(data);
					$('.card-section-wrap').css('opacity', '1');
				}, 600);
			}
		});
	}

	function searchAjax(query){
		$.ajax({
			type: "POST",
			url: ajaxUrl,
			dataType:'html',
			data: {
				action: 'ajax_search', query: query
			},
			success: function (data) {
				setTimeout(() => {
					$('.card-section-wrap').html(data);
					$('.card-section-wrap').css('opacity', '1');
				}, 600);
			}
		});
	}

});